import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Routes from "./Route/route";

const App = () => {
    return (
        <Router>
            <Switch>
                <Routes />
            </Switch>
        </Router>
    );
};

export default App;
