import React, { useEffect, useState } from "react";
import "./login.css";
import InputField from "./InputField";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

function Login({ authorized }) {
    let history = useHistory();
    const inputRefs = React.useRef([React.createRef(), React.createRef()]);

    const [data, setData] = React.useState({});

    const handleChange = (name, value) => {
        setData((prev) => ({ ...prev, [name]: value }));
    };

    const submitForm = (event) => {
        event.preventDefault(event);
        let logined = {
            email: event.target.username.value,
            password: event.target.password.value,
        };

        fetch(`${process.env.REACT_APP_API_ENDPOINT}user/login`, {
            headers: {
                "Content-Type": "application/json",
                // "api-key": `${process.env.REACT_APP_API_KEY}`,
            },
            method: "POST",
            body: JSON.stringify(logined),
        })
            .then(function (res) {
                return res.json();
            })
            .then((data) => {
                if (data.valid === true) {
                    authorized(true);
                    localStorage.setItem("authorized", data.valid);
                    history.push("/profile");
                } else {
                    Swal.fire({
                        title: "The email or password you entered isn’t connected to an account. Find your account and log in.",
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutUp",
                        },
                    });
                    history.push("/login");
                }
                return data;
            })
            .catch((error) => {
                console.log("error", error);
            });

        let isValid = true;

        for (let i = 0; i < inputRefs.current.length; i++) {
            const valid = inputRefs.current[i].current.validate();

            if (!valid) {
                isValid = false;
            }
        }

        if (!isValid) {
            return;
        }
    };

    return (
        <div className="App">
            <form onSubmit={submitForm} className="form">
                <h1>Adventure Works</h1>
                <InputField
                    ref={inputRefs.current[0]}
                    name="username"
                    label="Username*:"
                    onChange={handleChange}
                    validation={"required|min:4|max:12"}
                />
                <InputField
                    ref={inputRefs.current[1]}
                    name="password"
                    type="password"
                    label="Password*:"
                    validation="required|min:4"
                    onChange={handleChange}
                />
                <button type="submit">Login</button>
            </form>
        </div>
    );
}

export default Login;
