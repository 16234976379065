import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch,
} from "react-router-dom";

import ViewSales from "../ViewSales/viewSales";
import ViewProducts from "../ViewProducts/viewProducts";
import SaleProduct from "../SaleProduct/SaleProduct";
import Navbar from "../../Components/Navbar/Navbar";

const Profile = ({ authorized }) => {
    return (
        <Router>
            <Navbar authorized={authorized} />
            <main>
                <Switch>
                    <Route path="/saleProduct" exact>
                        <SaleProduct />
                    </Route>
                    <Route path="/viewSales" exact>
                        <ViewSales />
                    </Route>
                    <Route path="/products" exact>
                        <ViewProducts />
                    </Route>

                    <Redirect to="/saleProduct" />
                </Switch>
            </main>
        </Router>
    );
};

export default Profile;
