import React, { useEffect, useState, useMemo } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { BasicTable } from "../../Components/Tables/BasicTable";
// import { Index } from "./ModalAnnouncement/Index";
import moment from "moment";
import TableBasic from "../../Components/Tables/Actions";
import { COLUMNS } from "../../Components/Tables/Columns";
// import { Form } from "./ModalAnnouncement/Form";
// import { handleLogout } from "../redux/authentication";
// import { useDispatch } from "react-redux";
const ViewSales = () => {
    // const form = Form;
    // const dispatch = useDispatch();
    const [tableArr, setTableArr] = useState([]);
    const [collTable, setCollTable] = useState([]);
    const columns = useMemo(() => COLUMNS, []);
    const refreshTable = () => {
        // const accesToken = JSON.parse(
        //     localStorage.getItem("userData")
        // ).access_token;
        // console.log(accesToken);
        fetch(`${process.env.REACT_APP_API_ENDPOINT}sales/sales`, {
            method: "GET",
            // headers: {
            //     "Content-Type": "application/json",
            //     // "api-key": `${process.env.REACT_APP_API_KEY}`,
            //     // Authorization: `Bearer ${accesToken}`,
            // },
        })
            .then((response) => {
                if (!response.ok) {
                    throw response;
                }
                return response.json();
            })
            .then((data) => {
                setTableArr(
                    data.map((val) => {
                        return {
                            id: val.SalesOrderID,
                            customer: val.Customer,
                            product: val.Product,
                            unitPrice: val.UnitPrice,
                            shipDate: moment(val.ShipDate).format("YYYY-MM-DD"),
                            orderDate: moment(val.OrderDate).format(
                                "YYYY-MM-DD"
                            ),
                        };
                    })
                );
            })
            .catch((error) => {
                console.log("error", error.statusText);
            });
    };

    useEffect(() => {
        refreshTable();
    }, []);

    return (
        <div className="tableDiv">
            <Card>
                <CardBody>
                    <BasicTable tableArr={tableArr} columns={columns} />
                </CardBody>
            </Card>
        </div>
    );
};
export default ViewSales;
