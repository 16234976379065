import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./saleProduct.css";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";

const SaleProduct = () => {
    const [optionsCostumer, setCustumer] = useState([]);
    const [optionsProduct, setProduct] = useState([]);

    const inputRef = React.useRef([React.createRef(), React.createRef()]);

    useEffect(async () => {
        await fetch(`${process.env.REACT_APP_API_ENDPOINT}sales/customers`, {
            method: "GET",
        })
            .then((response) => {
                if (!response.ok) {
                    throw response;
                }
                return response.json();
            })
            .then((data) => {
                setCustumer(
                    data.map((val) => {
                        return {
                            value: val.CustomerId,
                            label: val.FullName,
                        };
                    })
                );
            })
            .catch((error) => {
                console.log("error", error.statusText);
            });
    }, []);

    useEffect(async () => {
        await fetch(`${process.env.REACT_APP_API_ENDPOINT}sales/products`, {
            method: "GET",
        })
            .then((response) => {
                if (!response.ok) {
                    throw response;
                }
                return response.json();
            })
            .then((data) => {
                setProduct(
                    data.map((val) => {
                        return {
                            value: val.ProductID,
                            label: val.Name,
                        };
                    })
                );
            })
            .catch((error) => {
                console.log("error", error.statusText);
            });
    }, []);

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;

    const submitSaleProduct = (event) => {
        event.preventDefault();
        let formData = {
            OrderDate:
                event.target.dateNow.value == ""
                    ? today
                    : event.target.dateNow.value,
            DueDate: event.target.dateDue.value,
            ShipDate: event.target.dateShip.value,
            CustomerID: parseInt(event.target.selectCustomer.value),
            ProductID: parseInt(event.target.selectProduct.value),
        };

        fetch(`${process.env.REACT_APP_API_ENDPOINT}sales/SaleProduct`, {
            headers: {
                "Content-Type": "application/json",
                // "api-key": `${process.env.REACT_APP_API_KEY}`,
            },
            method: "POST",
            body: JSON.stringify(formData),
        })
            .then((response) => {
                if (response.ok) {
                    event.target.reset();
                    Swal.fire({
                        icon: "success",
                        title: "The record successfully created!",
                        showConfirmButton: false,
                        timer: 1800,
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Something went wrong!!!",
                        showConfirmButton: false,
                        timer: 1800,
                    });
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    };
    return (
        <div className="container">
            <div className="parentDivSaleProd">
                <form onSubmit={submitSaleProduct} className="form saleProd">
                    <div className="formChildDiv">
                        <span>Order Date</span>
                        <input type="text" name="dateNow" placeholder={today} />
                        <span>Due Date</span>
                        <input type="date" name="dateDue" />
                        <span>Ship Date</span>
                        <input type="date" name="dateShip" />

                        <Select
                            ref={inputRef}
                            options={optionsCostumer}
                            id="selectCustomer"
                            placeholder="Costumer"
                            name="selectCustomer"
                        />

                        <Select
                            ref={inputRef}
                            options={optionsProduct}
                            id="selectProduct"
                            placeholder="Product"
                            name="selectProduct"
                        />
                    </div>
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
};
export default SaleProduct;
