import React, { useEffect } from "react";
import "./Navbar.css";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";

const Navbar = ({ authorized }) => {
    let history = useHistory();
    function animation() {
        var tabsNewAnim = $("#navbarSupportedContent");
        var activeItemNewAnim = tabsNewAnim.find(".active");
        var activeWidthNewAnimHeight = activeItemNewAnim.innerHeight();
        var activeWidthNewAnimWidth = activeItemNewAnim.innerWidth();
        var itemPosNewAnimTop = activeItemNewAnim.position();
        var itemPosNewAnimLeft = activeItemNewAnim.position();
        $(".hori-selector").css({
            top: itemPosNewAnimTop.top + "px",
            left: itemPosNewAnimLeft.left + "px",
            height: activeWidthNewAnimHeight + "px",
            width: activeWidthNewAnimWidth + "px",
        });
        $("#navbarSupportedContent").on("click", "li", function (e) {
            $("#navbarSupportedContent ul li").removeClass("active");
            $(this).addClass("active");
            var activeWidthNewAnimHeight = $(this).innerHeight();
            var activeWidthNewAnimWidth = $(this).innerWidth();
            var itemPosNewAnimTop = $(this).position();
            var itemPosNewAnimLeft = $(this).position();
            $(".hori-selector").css({
                top: itemPosNewAnimTop.top + "px",
                left: itemPosNewAnimLeft.left + "px",
                height: activeWidthNewAnimHeight + "px",
                width: activeWidthNewAnimWidth + "px",
            });
        });
    }

    useEffect(() => {
        animation();
        $(window).on("resize", function () {
            setTimeout(function () {
                animation();
            }, 500);
        });
    }, []);

    function clearAll(event) {
        event.preventDefault();
        window.localStorage.clear();
        authorized(false);
        history.push("/login");
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-mainbg">
            <NavLink className="navbar-brand navbar-logo" to="/" exact>
                Advanture Works
            </NavLink>

            <button
                className="navbar-toggler"
                onClick={function () {
                    setTimeout(function () {
                        animation();
                    });
                }}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <i className="fas fa-bars text-white"></i>
            </button>

            <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
            >
                <ul className="navbar-nav ml-auto">
                    <div className="hori-selector">
                        <div className="left"></div>
                        <div className="right"></div>
                    </div>

                    <li className="nav-item active">
                        <NavLink className="nav-link" to="/saleProduct" exact>
                            <i class="fas fa-balance-scale"></i>Sale a product
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink className="nav-link" to="/viewSales" exact>
                            <i class="fas fa-list"></i>View Sales
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink className="nav-link" to="/products" exact>
                            <i class="fab fa-product-hunt"></i>Products
                        </NavLink>
                    </li>
                </ul>
                <ul>
                    <li className="nav-item">
                        <NavLink
                            className="nav-link"
                            to="/login"
                            onClick={clearAll}
                            exact
                        >
                            <span className="logout">
                                <i class="fas fa-sign-out-alt"></i> Log out
                            </span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </nav>
    );
};
export default Navbar;
