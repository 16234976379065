import React, { useState } from "react";

import Login from "../pages/Login/login";
import Profile from "../pages/Profile/profile";

const Routes = () => {
    const saved = localStorage.getItem("authorized");
    const initial = JSON.parse(saved);

    let [authorized, setAuthorized] = useState(initial);

    if (authorized) {
        return <Profile authorized={setAuthorized} />;
    } else {
        return <Login authorized={setAuthorized} />;
    }
};

export default Routes;
